import JustValidate from 'just-validate';

const formCharlas = document.getElementById( 'contact-form-5' );
const formRegister = document.getElementById( 'contact-form-26' );

let validateCharlas = '';
let validateRegister = '';

if (formCharlas) {
    validateCharlas = new JustValidate( '#contact-form-5', {
        errorFieldCssClass: 'is-invalid',
        focusInvalidField: true,
        validateBeforeSubmitting: true,
    });
    
    validateCharlas
        .addField('#grado_cursa', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }
        ])
        .addField('#nombres', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, 
            {
                rule: 'minLength',
                value: 2,
                errorMessage: 'El texto ingresado es muy corto'
            },
            {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Solo puedes ingresar letras'
            }
        ])
        .addField('#apellido_paterno', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Solo puedes ingresar letras'
            }
        ])
        .addField('#apellido_materno', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Solo puedes ingresar letras'
            }
        ])
        .addField('#telefono_celular', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'number',
                errorMessage: 'Solo se aceptan dígitos'
            }, {
                rule: 'minLength',
                value: 9,
                errorMessage: 'El Celular debe tener 9 dígitos'
            }, {
                rule: 'minLength',
                value: 9,
                errorMessage: 'El Celular debe tener 9 dígitos'
            }
        ])
        .addField('#telefono_fijo', [
            {
                rule: 'number',
                errorMessage: 'Solo se aceptan dígitos'
            }, {
                rule: 'minLength',
                value: 7,
                errorMessage: 'El Número debe tener mínimo 7 dígitos'
            }, {
                rule: 'minLength',
                value: 9,
                errorMessage: 'El Número debe tener máximo 9 dígitos'
            }
        ])
        .addField('#email', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'email',
                errorMessage: 'Ingresa un email válido'
            }
        ])
        .addField('#dni', [
            {
                rule: 'number',
                errorMessage: 'Solo se aceptan dígitos'
            }, {
                rule: 'minLength',
                value: 8,
                errorMessage: 'El DNI debe tener 8 dígitos'
            }, {
                rule: 'maxLength',
                value: 8,
                errorMessage: 'El DNI debe tener 8 dígitos'
            }
        ])
        .addField('#direccion', [
            {
                rule: 'minLength',
                value: 10,
                errorMessage: 'Debes ingresar al menos 10 caracteres'
            },
        ])
        .addField('#distrito', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            },
        ])
        .addField('#otra_institucion', [
            {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Ingresa solo caracteres'
            }, {
                rule: 'minLength',
                value: 2,
                errorMessage: 'Debes ingresar al menos 2 dígitos'
            },
        ])
        .onSuccess( ( event ) => {
			//console.log( 'Validation passes and form submitted', event );
		});
    
    document.addEventListener('DOMContentLoaded', function() {
        var radios = document.getElementsByName('estudiar_en');
    
        for (var i = 0; i < radios.length; i++) {
            radios[i].addEventListener('change', function(e) {
                var institucion = e.target.value;
                ('Toulouse Lautrec' == institucion) ? document.querySelector('#otra_institucion').disabled = true : document.querySelector('#otra_institucion').disabled = false ;
            });
        }

        var carreras = document.getElementsByName('carreras[]');
        for (var carrera of carreras) {
            carrera.addEventListener('change', function(e) {
                var checked = document.querySelectorAll('input[type="checkbox"]:checked');
                var total = checked.length;
                if(total == 3) {
                    for (var i = 0; i < carreras.length; i++) {
                        if(carreras[i].checked == false) {
                            carreras[i].disabled = true;
                        }
                    }
                }else {
                    for (var i = 0; i < carreras.length; i++) {
                        carreras[i].disabled = false;
                    }
                }                
            });
        }
    }, false);
}

if (formRegister) {
    validateRegister = new JustValidate( '#contact-form-26', {
        errorFieldCssClass: 'is-invalid',
        focusInvalidField: true,
        validateBeforeSubmitting: true,
    });
    
    validateRegister
        .addField('#nombre_colegio', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Solo puedes ingresar letras'
            }
        ])
        .addField('#distrito_colegio', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }
        ])
        .addField('#grado_cursa', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }
        ])
        .addField('#nombres', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, 
            {
                rule: 'minLength',
                value: 2,
                errorMessage: 'El texto ingresado es muy corto'
            },
            {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Solo puedes ingresar letras'
            }
        ])
        .addField('#apellido_paterno', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Solo puedes ingresar letras'
            }
        ])
        .addField('#apellido_materno', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Solo puedes ingresar letras'
            }
        ])
        .addField('#telefono_celular', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'number',
                errorMessage: 'Solo se aceptan dígitos'
            }, {
                rule: 'minLength',
                value: 9,
                errorMessage: 'El Celular debe tener 9 dígitos'
            }, {
                rule: 'minLength',
                value: 9,
                errorMessage: 'El Celular debe tener 9 dígitos'
            }
        ])
        .addField('#telefono_fijo', [
            {
                rule: 'number',
                errorMessage: 'Solo se aceptan dígitos'
            }, {
                rule: 'minLength',
                value: 7,
                errorMessage: 'El Número debe tener mínimo 7 dígitos'
            }, {
                rule: 'minLength',
                value: 9,
                errorMessage: 'El Número debe tener máximo 9 dígitos'
            }
        ])
        .addField('#email', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            }, {
                rule: 'email',
                errorMessage: 'Ingresa un email válido'
            }
        ])
        .addField('#dni', [
            {
                rule: 'number',
                errorMessage: 'Solo se aceptan dígitos'
            }, {
                rule: 'minLength',
                value: 8,
                errorMessage: 'El DNI debe tener 8 dígitos'
            }, {
                rule: 'maxLength',
                value: 8,
                errorMessage: 'El DNI debe tener 8 dígitos'
            }
        ])
        .addField('#direccion', [
            {
                rule: 'minLength',
                value: 10,
                errorMessage: 'Debes ingresar al menos 10 caracteres'
            },
        ])
        .addField('#distrito', [
            {
                rule: 'required',
                errorMessage: 'Dato requerido'
            },
        ])
        .addField('#otra_institucion', [
            {
                rule: 'customRegexp',
                value: /^[a-zA-Z\s]*$/,
                errorMessage: 'Ingresa solo caracteres'
            }, {
                rule: 'minLength',
                value: 2,
                errorMessage: 'Debes ingresar al menos 2 dígitos'
            },
        ])
        .onSuccess( ( event ) => {
			//console.log( 'Validation passes and form submitted', event );
		});

    document.addEventListener('DOMContentLoaded', function() {
        var radios = document.getElementsByName('estudiar_en');
    
        for (var i = 0; i < radios.length; i++) {
            radios[i].addEventListener('change', function(e) {
                var institucion = e.target.value;
                ('Toulouse Lautrec' == institucion) ? document.querySelector('#otra_institucion').disabled = true : document.querySelector('#otra_institucion').disabled = false ;
            });
        }

        var carreras = document.getElementsByName('carreras[]');
        for (var carrera of carreras) {
            carrera.addEventListener('change', function(e) {
                var checked = document.querySelectorAll('input[type="checkbox"]:checked');
                var total = checked.length;
                if(total == 3) {
                    for (var i = 0; i < carreras.length; i++) {
                        if(carreras[i].checked == false) {
                            carreras[i].disabled = true;
                        }
                    }
                }else {
                    for (var i = 0; i < carreras.length; i++) {
                        carreras[i].disabled = false;
                    }
                }                
            });
        }
    }, false);

}